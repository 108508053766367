import { AllUserRoles } from "app/shared/generic_variables";
import { VillageType, VillageGroup } from "common/typings";

export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyBKpYAE9a72QVo8YuUlKbmN9LrYKdYzSms",
    authDomain: "marinavillage-dev.firebaseapp.com",
    databaseURL: "https://marinavillage-dev.firebaseio.com",
    projectId: "marinavillage-dev",
    storageBucket: "marinavillage-dev.appspot.com",
    messagingSenderId: "648040488287",
    appId: "1:648040488287:web:6839296f4db9ff82f66aea",
    measurementId: "G-X6EVNGKKEY",
  },
  apiEndPoint: "https://us-central1-marinavillage-dev.cloudfunctions.net",
  frontendUrl: "https://marinavillage-dev.web.app",
};

export const appName = "MarinaVillage Dev";

export const Roles = [
  AllUserRoles.district,
  AllUserRoles.admin,
  AllUserRoles.security,
  AllUserRoles.maintenance,
  AllUserRoles.vendor,
  AllUserRoles.vendor_employee,
  AllUserRoles.boater,
  AllUserRoles.pdFireEms,
];

export const strings = {
  appName: "MarinaVillage",
  villageType: VillageType.MARINA,
  villageGroup: VillageGroup.Harbor,
};

export const AllAlerts = [
  {
    key: "medical",
    value: "Medical",
    title: "A MEDICAL EMERGENCY has been reported",
  },
  {
    key: "armed",
    value: "Armed Assailant",
    title: "An ARMED ASSAILANT has been reported",
  },
  {
    key: "fight",
    value: "Fight",
    title: "A FIGHT has been reported",
  },
  {
    key: "fire",
    value: "Fire",
    title: "A FIRE has been reported",
  },
  {
    key: "intruder",
    value: "Intruder",
    title: "An INTRUDER has been reported",
  },
  {
    key: "other",
    value: "Other",
    title: "An EMERGENCY has been reported",
  },
  {
    key: "incident",
    value: "Incident",
    title: "An INCIDENT has been reported",
  },
  {
    key: "auto",
    value: "Auto",
    title: "An AUTO ACCIDENT/INJURY has been reported",
  },
  {
    key: "boat",
    value: "Boat",
    title: "A BOAT ACCIDENT/INJURY has been reported",
  },
  {
    key: "explosion",
    value: "Explosion",
    title: "An EXPLOSION has been reported",
  },
];
export const UserBulkUploadFileSampleTemplateFileName = "mv-user-bulk-upload-sample.xlsx";
